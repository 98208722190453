import React, { useEffect, useState } from 'react'
import { toast } from 'sonner';
import { useLoader } from '../Store';
import HttpService from '../Api';
import { useFormik } from 'formik';
import { IoMdCloseCircle } from 'react-icons/io';
import { LogoSchema } from '../Validation';

export default function SetLogo() {
    const setIsLoading = useLoader(state => state.setIsLoading);
    const [logo, setLogo] = useState({ name: "", logo: null });

    const { handleChange, handleSubmit, values, errors, touched, resetForm, setFieldValue } = useFormik({
        initialValues: {
            name: logo.name,
            logo: logo.logo,
        },
        validationSchema: LogoSchema,
        enableReinitialize: true,
        onSubmit: (data) => {
            setIsLoading(true);
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("logo", data.logo!);
            HttpService({
                method: 'post',
                url: '/admin/set/logo',
                body: formData
            }).then((resp) => {
                resetForm();
                setIsLoading(false);
                toast.success(resp.data?.message);
                getLogo();
            }).catch((err) => {
                setIsLoading(false);
                toast.error(err?.response?.data?.message);
            });
        }
    });

    const getLogo = () => {

        HttpService({
            url: '/admin/get/logo',
            method: 'get'
        }).then((resp) => {
            if (resp.data?.logo) {
                setLogo(resp.data?.logo)
            }
        }).catch((err) => {
            setIsLoading(false);
            toast.error(err?.response?.data?.message);
        });
    }
    useEffect(() => {
        getLogo();
        // eslint-disable-next-line
    }, [])

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="card">

                        <div className="card-header">
                            <h3 className="card-title font-weight-bolder h5">Set Logo</h3>
                        </div>


                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-12 col-lg-6 col-xl-5'>
                                        <div className="form-group">
                                            <label htmlFor="name">Website name</label>
                                            <input type="text" className="form-control" id="name" value={values.name} onChange={handleChange} placeholder="Please Enter Category Name." />
                                            {errors.name && touched.name ? <div className='text-danger h6'>{errors.name}</div> : null}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="image">Logo</label>
                                            {
                                                values?.logo ?
                                                    <div className='position-relative' style={{ width: 150, height: 150 }}>
                                                        <IoMdCloseCircle className='cursor-pointer h4 m-0 position-absolute text-gray font-weight-bolder' onClick={() => setFieldValue('logo', null)} style={{ top: '2%', left: '82%' }} />
                                                        <img alt='media' className='w-100 h-100 object-fit-cover m-0' src={typeof values.logo === 'string' ? `${process.env.REACT_APP_BASE_URL}/images/logo/${values?.logo}` : URL.createObjectURL(values?.logo)} />
                                                    </div> :
                                                    <input accept="logo/*" onChange={(e: any) => setFieldValue('logo', e.target.files[0])} type="file" readOnly={true} className="form-control" id="logo" placeholder="Please Enter Category Name." />
                                            }
                                            {errors.logo && touched.logo ? <div className='text-danger h6'>{errors.logo}</div> : null}
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="card-footer clearfix">
                                <button className='btn btn-primary mr-2' style={{ width: 100 }} type='submit'>Submit</button>
                                <button className='btn btn-secondary ml-2' style={{ width: 100 }} type='button' onClick={() => resetForm()}>Reset</button>
                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </div>
    )
}
