import { useFormik } from 'formik';
import { useState } from 'react';
import { LoginSchema } from '../Validation';
import HttpService from '../Api';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const [showPwd, setShowPwd] = useState<boolean>(false);
    const navigate = useNavigate();

    const { handleChange, handleSubmit, values, errors, touched } = useFormik({
        initialValues: {
            userName: '',
            password: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (data) => {
            HttpService({
                url: '/admin/login',
                method: 'post',
                body: data
            }).then((result) => {
                toast.success(result?.data?.message);
                localStorage.setItem('admin_token', result?.data?.token);
                navigate('/');
            }).catch((err) => {
                toast.error(err?.response?.data?.message);
            });
        }
    })
    return (
        <div className='hold-transition login-page'>
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <p className="h1"><b>Admin</b></p>
                    </div>
                    <div className="card-body">
                        <p className="login-box-msg">Sign in to start your session</p>
                        <form className='mb-4' onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <div className="input-group">
                                    <input name='userName' value={values.userName} onChange={handleChange} type="text" className="form-control" placeholder="userName/E-mail" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope" />
                                        </div>
                                    </div>
                                </div>
                                {
                                    errors?.userName && touched?.userName ?
                                        <div className='text-danger'>{errors?.userName}</div>
                                        : null
                                }
                            </div>
                            <div className="mb-3">
                                <div className="input-group mb-3">
                                    <input type={showPwd ? "text" : "password"} name='password' value={values.password} onChange={handleChange} className="form-control" placeholder="Password" />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            {
                                                showPwd ?
                                                    <span className="fas fa-eye cursor-pointer" onClick={() => setShowPwd(false)} />
                                                    :
                                                    <span className="fas fa-eye-slash cursor-pointer" onClick={() => setShowPwd(true)} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    errors?.password && touched?.password ?
                                        <div className='text-danger'>{errors?.password}</div>
                                        : null
                                }
                            </div>
                            <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                        </form>

                    </div>
                </div>
            </div>

        </div>
    )
}
