import React from 'react';
import { Line, Pie } from 'react-chartjs-2';
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
Chart.register(CategoryScale);
interface LineChartDataType {
  data: {
    labels: string[],
    values: number[],
  }
}


export const LineChart = ({ data }: LineChartDataType) => {
  const Data = {
    labels: data?.labels,
    datasets: [
      {
        label: 'Views',
        data: data?.values,
        fill: true,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
      },
    ],
  };

  return <Line data={Data} options={{plugins:{
    title: {
      display: true,
      text: "Daily Post Views"
    }
  }}} />;
};

export const PieChart = ({ data }: LineChartDataType) => {
  const Data = {
    labels: data?.labels,
    datasets: [
      {
        label: 'Daily Post Views',
        data: data?.values,
        fill: true,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 10,
      },
    ],
  };

  return <Pie data={Data} options={{plugins:{
    title: {
      display: true,
      text: "Users Gained between 2016-2020"
    }
  }}} />;
};


