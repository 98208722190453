import Http from './interceptor';

type HttpMethod = 'get' | 'post' | 'put' | 'delete';
interface apiType {
    url: string,
    body?: object,
    method: HttpMethod
}
const HttpService = async ({ url, body, method }: apiType) => {
    if (method === 'get') {
        const resp = await Http[method](url);
        return resp;
    } else {
        const resp = await Http[method](url, body);
        return resp;
    }
};

export default HttpService;