import React, { useEffect, useState } from 'react'
import { useLoader } from '../../Store';
import { postType } from '.';
import HttpService from '../../Api';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { IoArrowBack } from 'react-icons/io5';

export default function View() {
  const setIsLoading = useLoader(state => state.setIsLoading);
  const [post, setPost] = useState<postType>();
  const navigate = useNavigate();
  const { i } = useParams();

  useEffect(() => {
    HttpService({
      method: 'get',
      url: `/admin/post/list/${i}`
    }).then((resp) => {
      setPost(resp?.data?.post);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err?.response?.data?.message);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">

            <div className="card-header">
              <h3 className="card-title font-weight-bolder h5">Post View</h3>
              <button className='btn btn-info float-right font-weight-bolder' onClick={() => navigate(-1)}><IoArrowBack /> Back</button>
            </div>

            <div className="card-body">
              <table className="table table-bordered">
                {
                  post ?
                    <tbody>
                      <tr>
                        <td style={{ width: '200px' }}>Name</td>
                        <td>{post?.title}</td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>{post?.description}</td>
                      </tr>
                      <tr>
                        <td>Category</td>
                        <td>{post?.category?.name}</td>
                      </tr>
                      <tr>
                        <td>Sub Category</td>
                        <td>{post?.sub_category?.name || "--"}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>{post?.status ? "Active" : "Deactive"}</td>
                      </tr>
                    </tbody>
                    : null
                }
              </table>
              <div className='ck-content'>
                <label className='font-weight-bolder h4'>Preview:-</label>
                {post ? <div dangerouslySetInnerHTML={{ __html: post?.post }} /> : null}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
