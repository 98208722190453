
import React, { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";

const ProtectedRoute = (props:any) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserToken = () => {
        const userToken = localStorage.getItem('admin_token');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            return navigate('/login');
        }else{
            setIsLoggedIn(true);
        }
    }
    useEffect(() => {
        checkUserToken()
          // eslint-disable-next-line react-hooks/exhaustive-deps
        },[]);
    return (
        <>
            {
                isLoggedIn ? props.children  : null
            }
        
        </>
    );
}

export default ProtectedRoute;
