import React, { useEffect, useState } from 'react'
import HttpService from '../../Api';
import { FaTrashCan } from "react-icons/fa6";
import { FaEdit, FaEye } from "react-icons/fa";
import ReactPaginate from 'react-paginate';
import { IoAdd } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../../Store';
import CustomModal from '../../Components/Modal';
import { toast } from 'sonner';


export interface categoryType {
  id: number,
  name: string,
  description: string,
  status: number,
  image: string | File,
  color: string,
}

export default function Index() {

  const navigate = useNavigate();
  const setIsLoading = useLoader(state => state.setIsLoading);
  const [category, setCategory] = useState<categoryType[]>();
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const getData = () => {
    HttpService({
      url: `/admin/category/list?search=${search}&limit=10&page=${page}`,
      method: 'get'
    }).then((result: any) => {
      setCategory(result.data.category);
      setTotalPage(result?.data?.totalPage);
    }).catch((err) => toast.error(err?.response?.data?.message));
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', getData);
    getData();
    // eslint-disable-next-line
  }, [page, search]);

  const handleDelete = () => {
    if (deleteId) {
      setIsLoading(true);
      HttpService({
        url: `/admin/category/delete/${deleteId}`,
        method: 'delete'
      }).then((resp) => {
        setIsLoading(false);
        toast.success(resp?.data?.message);
        getData();
      }).catch((err) => {
        toast.error(err?.response?.data?.message);
        setIsLoading(false);
      });
      setShow(false);
      setDeleteId(null);
    }
  }

  return (

    <div className="content-wrapper">
      <CustomModal
        show={show}
        setShow={setShow}
        header='Delete Category'
        description='Do You really want to delete this category?'
        primaryButton='Delete'
        secondaryButton='Cancel'
        type='danger'
        onClick={handleDelete}
      />
      <div className="content-header">
        <div className="container-fluid">
          <div className='d-flex mb-2 mx-2'>
            <h3 className="card-title font-weight-bolder h5">Category List</h3>
            <div className='flex-grow-1' />
            <button className='btn btn-primary float-right font-weight-bolder' onClick={() => navigate('/category/add')}><IoAdd /> Add</button>
          </div>
          <div className="card">

            <div className="card-header">
              <div className='row justify-content-end my-1'>
                <div className='col-lg-4 col-md-6 col-12'>
                  <input className='form-control' placeholder='Search here...' value={search} onChange={(e) => setSearch(e.target.value)} />
                </div>
              </div>
            </div>
            <div className="card-header">

            </div>

            <div className="card-body">
              <div className='table-responsive'>
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ width: 50 }}>#</th>
                      <th>Name</th>
                      <th style={{ width: 150 }}>status</th>
                      <th style={{ width: 150 }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      category && category.map((item, i) => (
                        <tr key={i}>
                          <td>{((page - 1) * 10) + i + 1}.</td>
                          <td>{item?.name}</td>
                          <td>
                            {item?.status ? <button className="btn btn-outline-success w-100">Active</button> : <button className="btn btn-outline-danger w-100">Deactive</button>}
                          </td>
                          <td>
                            <div className='my-2 d-flex justify-content-center align-items-center'>
                              <FaEye className='h5 text-info mb-0 cursor-pointer mx-2' onClick={() => {
                                navigate(`/category/view/${item.id}`);
                                setIsLoading(true);
                              }} />
                              <FaEdit className='h5 text-primary mb-0 cursor-pointer mx-2' onClick={() => {
                                navigate(`/category/update/${item.id}`);
                                setIsLoading(true);
                              }} />
                              <FaTrashCan className='h6 text-danger mb-0 cursor-pointer mx-2' onClick={() => {
                                setShow(true);
                                setDeleteId(item?.id);
                              }} />
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer clearfix">
              <ReactPaginate
                breakLabel="..."
                nextLabel="»"
                onPageChange={page => setPage(page.selected + 1)}
                pageRangeDisplayed={2}
                forcePage={0}
                className='pagination pagination-md m-0 float-right user-select-none'
                pageClassName='page-item page-link user-select-none'
                nextClassName='page-item page-link user-select-none'
                breakClassName='page-item page-link user-select-none'
                previousClassName='page-item page-link user-select-none'
                activeClassName='active'
                pageCount={totalPage}
                previousLabel="«"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
