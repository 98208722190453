import React from 'react'
import { Modal } from 'react-bootstrap'
import { IoMdCloseCircle } from "react-icons/io";

interface modalPropsType {
    show: boolean,
    setShow: (value: boolean) => void,
    image: string,
}

export default function ImageModal({ show, setShow, image }: modalPropsType) {
    return (
        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size='xl'
            contentClassName='bg-transparent'
        >
            <Modal.Body className='p-0'>
                <div className='position-relative h-100 w-100'>
                    <img className='h-100 w-100' src={image} alt='media' />
                    <IoMdCloseCircle className='cursor-pointer h2 m-0 position-absolute text-white font-weight-bolder' onClick={() => setShow(false)} style={{ top: '2%', left: '96%' }}/>
                </div>
            </Modal.Body>
        </Modal>
    )
}
