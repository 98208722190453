import React, { useEffect, useState } from 'react'
import { CloseButton, Modal } from 'react-bootstrap'
import { mediaType } from '../Pages/Media';
import HttpService from '../Api';
import { toast } from 'sonner';

interface modalPropsType {
    show: boolean,
    setShow: (value: boolean) => void,
    onClick: (url: string, alt: string) => void
}

export default function MediaModal({ show, setShow, onClick }: modalPropsType) {

    const [media, setMedia] = useState<mediaType[]>();
    const [totalPage, setTotalPage] = useState(0);
    const [limit, setLimit] = useState(50);

    const getData = () => {
        HttpService({
            url: `/admin/media/list?limit=${limit}`,
            method: 'get'
        }).then((result: any) => {
            setMedia(result.data.media);
            setTotalPage(result?.data?.totalPage);
        }).catch((err) => toast.error(err?.response?.data?.message));
    }

    useEffect(() => {
        document.addEventListener('visibilitychange', getData);
        getData();
        // eslint-disable-next-line
    }, [limit]);
    return (
        <Modal
            show={show}
            size='xl'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className='d-flex justify-content-end'>
                    <CloseButton onClick={() => setShow(false)} />
                </div>
                <div className="d-flex flex-wrap" style={{ maxHeight: 'calc(100vh - 100px)' }}>
                    {
                        media ? media.map((item, i) => (<div className='m-2 cursor-pointer' key={i} onClick={() => onClick(`${process.env.REACT_APP_IMAGE_URL}${item?.media}`, item?.media)}>
                            <div className='position-relative' style={{ width: 150, height: 150 }}>
                                <img alt={item?.media} className='w-100 h-100 object-fit-cover m-0' src={`${process.env.REACT_APP_IMAGE_URL}${item?.media}`} />
                            </div>
                        </div>))
                            : null
                    }
                </div>
                {
                    totalPage > 1 &&
                    <div className='d-flex justify-content-center'>
                        <button className='btn btn-primary' onClick={() => setLimit(limit + 25)}>Load more</button>
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}
