import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
    userName: Yup.string().required('Email is required'),
    password: Yup.string().min(6).required('password is required'),
});

export const CategorySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    isImage: Yup.boolean().required(),
    color: Yup.string().required('color is required'),
    status: Yup.number().required('Status is required'),
    image: Yup.mixed().when('isImage', {
        is: true,
        then: (schema) => schema.required()
    }).nullable(),
});

export const SubCategorySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    isImage: Yup.boolean().required(),
    status: Yup.number().required('Status is required'),
    categoryId: Yup.number().required('Category is required'),
    image: Yup.mixed().when('isImage', {
        is: true,
        then: (schema) => schema.required()
    }).nullable(),
});

export const PostSchema = Yup.object().shape({
    title: Yup.string().required('Post Title is required'),
    description: Yup.string().required('Description is required'),
    isImage: Yup.boolean().required(),
    status: Yup.number().required('Status is required'),
    categoryId: Yup.number().required('Category is required'),
    subCategoryId: Yup.number().nullable(),
    post: Yup.string().required('Post is required'),
    metaTitle: Yup.string().required('Meta title is required'),
    metaDescription: Yup.string().required('Meta Description is required'),
    metaKeyword: Yup.string().required('Meta Keyword is required'),
    image: Yup.mixed().when('isImage', {
        is: true,
        then: (schema) => schema.required()
    }).nullable(),
});

export const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().min(7).required('Please enter old password.'),
    newPassword: Yup.string().min(7).required('Please enter new password.'),
    confirmPassword: Yup.string().oneOf([Yup.ref('newPassword')], "New password and confirm password do not match.").required('Please enter confirm password.'),
});

export const MediaSchema = Yup.object().shape({
    image: Yup.mixed().required('Select at least one image.')
});

export const LogoSchema = Yup.object().shape({
    name: Yup.string().required('Website name is required'),
    logo: Yup.mixed().nullable(),
})