import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BiSolidCategory } from "react-icons/bi";
import { FaChartPie, FaImage, FaLock, FaRegNewspaper } from "react-icons/fa";
import { MdCategory, MdPermMedia } from "react-icons/md";

const sidebarMenu = [
  {
    path: '/',
    heading: 'Dashboard',
    active: '',
    icon: <FaChartPie className='nav-icon' />
  },
  {
    path: '/category',
    heading: 'Category',
    active: 'category',
    icon: <BiSolidCategory className='nav-icon' />
  },
  {
    path: '/sub-category',
    heading: 'Sub Category',
    active: 'sub-category',
    icon: <MdCategory className='nav-icon' />
  },
  {
    path: '/post',
    heading: 'Post',
    active: 'post',
    icon: <FaRegNewspaper className='nav-icon' />
  },
  {
    path: '/media',
    heading: 'Media',
    active: 'media',
    icon: <MdPermMedia className='nav-icon' />
  },
  {
    path: '/set-logo',
    heading: 'Set Logo',
    active: 'set-logo',
    icon: <FaImage className='nav-icon' />
  },
  {
    path: '/change-password',
    heading: 'Change password',
    active: 'change-password',
    icon: <FaLock className='nav-icon' />
  },
];

export default function Sidebar() {
  const navigate = useNavigate();
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <p className="brand-link cursor-pointer" onClick={() => navigate('/')}>
        <img src="/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
        <span className="brand-text font-weight-light">Admin Panel</span>
      </p>
      <div className="sidebar">
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            {
              sidebarMenu.map((item, i) => (
                <li className="nav-item" key={i}>
                  <Link to={item?.path} className="nav-link">
                    {item.icon}
                    <p>{item?.heading}</p>
                  </Link>
                </li>
              ))
            }
          </ul>
        </nav>
      </div>
    </aside>

  )
}
