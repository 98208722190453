import React, { useEffect, useState } from 'react'
import { useLoader } from '../../Store';
import { categoryType } from '.';
import HttpService from '../../Api';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { IoArrowBack } from 'react-icons/io5';
import ImageModal from '../../Components/ImageModal';

export default function View() {
  const setIsLoading = useLoader(state => state.setIsLoading);
  const [category, setCategory] = useState<categoryType>();
  const [showImage, setShowImage] = useState(false);
  const [image, setImage] = useState('');

  const navigate = useNavigate();
  const { i } = useParams();

  useEffect(() => {
    HttpService({
      method: 'get',
      url: `/admin/category/list/${i}`
    }).then((resp) => {
      setCategory(resp?.data?.category);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err?.response?.data?.message);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="content-wrapper">
      <ImageModal show={showImage} setShow={setShowImage} image={image} />
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">

            <div className="card-header">
              <h3 className="card-title font-weight-bolder h5">Category View</h3>
              <button className='btn btn-info float-right font-weight-bolder' onClick={() => navigate(-1)}><IoArrowBack /> Back</button>
            </div>

            <div className="card-body">
              <table className="table table-bordered">
                {
                  category ?
                    <tbody>
                      <tr>
                        <td style={{ width: '200px' }}>Name</td>
                        <td>{category?.name}</td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>{category?.description}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>{category?.status ? "Active" : "Deactive"}</td>
                      </tr>
                      <tr>
                        <td>Color</td>
                        <td>
                          <div style={{ width: 50, height: 50, backgroundColor: `${category?.color}` }} />
                        </td>
                      </tr>
                      <tr>
                        <td>Image</td>
                        <td>
                          <div className='position-relative cursor-pointer' style={{ width: 250, height: 250 }}>
                            <img onClick={() => {
                              setShowImage(true);
                              setImage(`${process.env.REACT_APP_IMAGE_URL}${category?.image}`);
                            }} alt='media' className='w-100 h-100 object-fit-cover m-0' src={`${process.env.REACT_APP_IMAGE_URL}/${category?.image}`} />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    : null
                }
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
