import React, { useEffect, useState } from 'react'
import { useLoader } from '../../Store';
import { subCategoryType } from '.';
import HttpService from '../../Api';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { IoArrowBack } from 'react-icons/io5';

export default function View() {
  const setIsLoading = useLoader(state => state.setIsLoading);
  const [subCategory, setCategory] = useState<subCategoryType>();
  const navigate = useNavigate();
  const { i } = useParams();

  useEffect(() => {
    HttpService({
      method: 'get',
      url: `/admin/sub-category/list/${i}`
    }).then((resp) => {
      setCategory(resp?.data?.subCategory);
      setIsLoading(false);
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err?.response?.data?.message);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">

            <div className="card-header">
              <h3 className="card-title font-weight-bolder h5">Sub Category View</h3>
              <button className='btn btn-info float-right font-weight-bolder' onClick={() => navigate(-1)}><IoArrowBack /> Back</button>
            </div>

            <div className="card-body">
              <table className="table table-bordered">
                {
                  subCategory ?
                    <tbody>
                      <tr>
                        <td style={{ width: '200px' }}>Name</td>
                        <td>{subCategory?.name}</td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>{subCategory?.description}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>{subCategory?.status ? "Active" : "Deactive"}</td>
                      </tr>
                    </tbody>
                    : null
                }
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
