import React, { useState } from 'react'
import { toast } from 'sonner';
import { useLoader } from '../Store';
import HttpService from '../Api';
import { useFormik } from 'formik';
import { ChangePasswordSchema } from '../Validation';

export default function ChangePassword() {
  const setIsLoading = useLoader(state => state.setIsLoading);
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false
  })

  const { handleChange, handleSubmit, values, errors, touched, resetForm } = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: (data) => {
      setIsLoading(true);
      HttpService({
        method: 'post',
        url: '/admin/change/password',
        body: data
      }).then((resp) => {
        resetForm();
        setIsLoading(false);
        toast.success(resp.data?.message);
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });
    }
  })

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">

            <div className="card-header">
              <h3 className="card-title font-weight-bolder h5">change password</h3>
            </div>


            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className='row'>
                  <div className='col-12 col-lg-6 col-xl-5'>
                    <div className="mb-3">
                      <div className="input-group">
                        <input type={showPassword.oldPassword ? "text" : "password"} name='oldPassword' value={values.oldPassword} onChange={handleChange} className="form-control" placeholder="Please enter old password." />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            {
                              showPassword.oldPassword ?
                                <span className="fas fa-eye cursor-pointer" onClick={() => setShowPassword({ ...showPassword, oldPassword: showPassword.oldPassword ? false : true })} />
                                :
                                <span className="fas fa-eye-slash cursor-pointer" onClick={() => setShowPassword({ ...showPassword, oldPassword: showPassword.oldPassword ? false : true })} />
                            }
                          </div>
                        </div>
                      </div>
                      {
                        errors?.oldPassword && touched?.oldPassword ?
                          <div className='text-danger'>{errors?.oldPassword}</div>
                          : null
                      }
                    </div>
                    <div className="mb-3">
                      <div className="input-group">
                        <input type={showPassword.newPassword ? "text" : "password"} name='newPassword' value={values.newPassword} onChange={handleChange} className="form-control" placeholder="Please enter new password." />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            {
                              showPassword.newPassword ?
                                <span className="fas fa-eye cursor-pointer" onClick={() => setShowPassword({ ...showPassword, newPassword: showPassword.newPassword ? false : true })} />
                                :
                                <span className="fas fa-eye-slash cursor-pointer" onClick={() => setShowPassword({ ...showPassword, newPassword: showPassword.newPassword ? false : true })} />
                            }
                          </div>
                        </div>
                      </div>
                      {
                        errors?.newPassword && touched?.newPassword ?
                          <div className='text-danger'>{errors?.newPassword}</div>
                          : null
                      }
                    </div>
                    <div className="mb-3">
                      <div className="input-group">
                        <input type={showPassword.confirmPassword ? "text" : "password"} name='confirmPassword' value={values.confirmPassword} onChange={handleChange} className="form-control" placeholder="Please confirm your password." />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            {
                              showPassword.confirmPassword ?
                                <span className="fas fa-eye cursor-pointer" onClick={() => setShowPassword({ ...showPassword, confirmPassword: showPassword.confirmPassword ? false : true })} />
                                :
                                <span className="fas fa-eye-slash cursor-pointer" onClick={() => setShowPassword({ ...showPassword, confirmPassword: showPassword.confirmPassword ? false : true })} />
                            }
                          </div>
                        </div>
                      </div>
                      {
                        errors?.confirmPassword && touched?.confirmPassword ?
                          <div className='text-danger'>{errors?.confirmPassword}</div>
                          : null
                      }
                    </div>


                  </div>
                </div>
              </div>
              <div className="card-footer clearfix">
                <button className='btn btn-primary mr-2' style={{ width: 100 }} type='submit'>Submit</button>
                <button className='btn btn-secondary ml-2' style={{ width: 100 }} type='button' onClick={() => resetForm()}>Reset</button>
              </div>
            </form>


          </div>
        </div>
      </div>
    </div>
  )
}
