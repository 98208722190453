import React, { useEffect, useState } from 'react';
import { BiSolidCategory } from 'react-icons/bi'
import { FaEye, FaRegNewspaper } from 'react-icons/fa'
import { MdCategory } from 'react-icons/md'
import { LineChart } from '../Components/Chart';
import HttpService from '../Api';
import { toast } from 'sonner';

interface dashboardDataType {
  postCount: number,
  categoryCount: number,
  subCategoryCount: number,
  totalViews: number,
  ChartData: {
    date: string,
    count: number
  }[]
}
export default function Dashboard() {

  const [dashboardData, setDashboardData] = useState<dashboardDataType>();
  const [chartData, setChartData] = useState<{ labels: string[], values: number[] }>({ labels: [], values: [] })
  const getData = () => {
    HttpService({
      url: `/admin/dashboard`,
      method: 'get'
    }).then((result: any) => {
      setDashboardData(result.data);
    }).catch((err) => toast.error(err?.response?.data?.message));
  };
  // eslint-disable-next-line
  useEffect(() => getData(), []);
  useEffect(() => {
    const values = Array.from({ length: 15 }).map((_, i) => {
      const d1 = new Date();
      const d2 = new Date(d1.setDate(d1.getDate() - (15 - i)));
      const allDates = dashboardData?.ChartData.map(item => item?.date);
      if (allDates?.includes(`${d2.getFullYear()}-${d2.getMonth() === 0 ? 1 : d2.getMonth() + 1}-${d2.getDate()}`)) {
        return dashboardData?.ChartData[allDates?.indexOf(`${d2.getFullYear()}-${d2.getMonth() === 0 ? 1 : d2.getMonth() + 1}-${d2.getDate()}`)].count || 0;
      } else return 0
    });
    const label = Array.from({ length: 15 }).map((_, i) => {
      const d1 = new Date();
      const d2 = new Date(d1.setDate(d1.getDate() - (15 - i)));
      return `${d2.getFullYear()}-${d2.getMonth() === 0 ? 1 : d2.getMonth() + 1}-${d2.getDate()}`;
    });
    setChartData({ values: values, labels: label });
    // eslint-disable-next-line
  }, [dashboardData])

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          {/* Info boxes */}
          <div className="row">
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box">
                <span className="info-box-icon bg-info elevation-1"><FaRegNewspaper /></span>
                <div className="info-box-content">
                  <span className="info-box-text">Post</span>
                  <span className="info-box-number">{dashboardData?.postCount || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-danger elevation-1"><BiSolidCategory /></span>
                <div className="info-box-content">
                  <span className="info-box-text">Category</span>
                  <span className="info-box-number">{dashboardData?.categoryCount || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-success elevation-1"><MdCategory /></span>
                <div className="info-box-content">
                  <span className="info-box-text">Sub Category</span>
                  <span className="info-box-number">{dashboardData?.subCategoryCount || 0}</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-3">
              <div className="info-box mb-3">
                <span className="info-box-icon bg-warning elevation-1"><FaEye /></span>
                <div className="info-box-content">
                  <span className="info-box-text">Total Views</span>
                  <span className="info-box-number">{dashboardData?.totalViews || 0}</span>
                </div>
              </div>
            </div>

          </div>

          <div className='row'>
            <div className='col-lg-8 col-12'>

              <LineChart data={chartData} />
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}
