import React from 'react'

export default function Footer() {
    return (
        <footer className="main-footer">
            <strong>Copyright © 2024</strong>
            <div className="float-right d-none d-sm-inline-block">
                <b>Version</b> 1.0
            </div>
        </footer>

    )
}
