import Category from './Pages/Category';
import CategoryAdd from './Pages/Category/Add';
import CategoryEdit from './Pages/Category/Edit';
import CategoryView from './Pages/Category/View';
import SubCategory from './Pages/SubCategory';
import SubCategoryAdd from './Pages/SubCategory/Add';
import SubCategoryEdit from './Pages/SubCategory/Edit';
import SubCategoryView from './Pages/SubCategory/View';
import Post from './Pages/Post';
import PostAdd from './Pages/Post/Add';
import PostEdit from './Pages/Post/Edit';
import PostView from './Pages/Post/View';
import ChangePassword from './Pages/ChangePassword';
import Media from './Pages/Media';
import MediaAdd from './Pages/Media/Add';
import SetLogo from './Pages/SetLogo';
import Dashboard from './Pages/Dashboard';

const AdminRoutes = [

    {path:"", element:<Dashboard/>},

    // Category
    { path: 'category', element: <Category /> },
    { path: 'category/add', element: <CategoryAdd /> },
    { path: 'category/update/:i', element: <CategoryEdit /> },
    { path: 'category/view/:i', element: <CategoryView /> },

    // Sub Category
    { path: 'sub-category', element: <SubCategory /> },
    { path: 'sub-category/add', element: <SubCategoryAdd /> },
    { path: 'sub-category/update/:i', element: <SubCategoryEdit /> },
    { path: 'sub-category/view/:i', element: <SubCategoryView /> },

    // Post
    { path: 'post', element: <Post /> },
    { path: 'post/add', element: <PostAdd /> },
    { path: 'post/update/:i', element: <PostEdit /> },
    { path: 'post/view/:i', element: <PostView /> },

    
    // Post
    { path: 'media', element: <Media /> },
    { path: 'media/add', element: <MediaAdd /> },

    //change-password
    { path: 'change-password', element: <ChangePassword /> },
    { path: 'set-logo', element: <SetLogo /> }
];
export default AdminRoutes;