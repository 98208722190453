import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { CgDanger } from "react-icons/cg";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";

interface modalPropsType {
    show: boolean,
    setShow: (value: boolean) => void,
    primaryButton: string,
    secondaryButton: string,
    type: "danger" | "success" | "warning",
    header: string,
    description: string
    onClick: () => void
}

export default function CustomModal({ show, setShow, primaryButton, secondaryButton, type, header, description, onClick }: modalPropsType) {
    return (
        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className='d-flex justify-content-center align-items-center'>
                    {type === 'danger' && <CgDanger className='text-danger' style={{ fontSize: 75 }} />}
                    {type === 'success' && <FaCheckCircle className='text-success' style={{ fontSize: 75 }} />}
                    {type === 'warning' && <IoIosWarning className='text-warning' style={{ fontSize: 75 }} />}
                </div>

                <div className='d-flex flex-column justify-content-center text-center my-3'>
                    <h4 className='font-weight-bolder'>{header}</h4>
                    <p>{description}</p>
                </div>
                <div className='d-flex justify-content-center my-2'>
                    <Button className='mx-2' style={{ width: '120px' }} variant={type} onClick={onClick}>{primaryButton}</Button>
                    <Button className='mx-2' style={{ width: '120px' }} variant='secondary' onClick={() => setShow(false)}>{secondaryButton}</Button>

                </div>
            </Modal.Body>
        </Modal>
    )
}
