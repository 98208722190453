import React, { useEffect, useState } from 'react'
import HttpService from '../../Api';
import ReactPaginate from 'react-paginate';
import { IoAdd } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../../Store';
import CustomModal from '../../Components/Modal';
import { toast } from 'sonner';
import { IoMdCloseCircle } from 'react-icons/io';


export interface mediaType {
    id: number,
    media: string,
}

export default function Index() {

    const navigate = useNavigate();
    const setIsLoading = useLoader(state => state.setIsLoading);
    const [media, setMedia] = useState<mediaType[]>();
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState<number>(1);
    // const [search, setSearch] = useState<string>("");
    const [show, setShow] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);

    const getData = () => {
        HttpService({
            url: `/admin/media/list?limit=30&page=${page}`,
            method: 'get'
        }).then((result: any) => {
            setMedia(result.data.media);
            setTotalPage(result?.data?.totalPage);
        }).catch((err) => toast.error(err?.response?.data?.message));
    }

    useEffect(() => {
        document.addEventListener('visibilitychange', getData);
        getData();
        // eslint-disable-next-line
    }, [page]);

    const handleDelete = () => {
        if (deleteId) {
            setIsLoading(true);
            HttpService({
                url: `/admin/media/delete/${deleteId}`,
                method: 'delete'
            }).then((resp) => {
                setIsLoading(false);
                toast.success(resp?.data?.message);
                getData();
            }).catch((err) => {
                toast.error(err?.response?.data?.message);
                setIsLoading(false);
            });
            setShow(false);
            setDeleteId(null);
        }
    }

    return (

        <div className="content-wrapper">
            <CustomModal
                show={show}
                setShow={setShow}
                header='Delete Media'
                description='Do You really want to delete this media?'
                primaryButton='Delete'
                secondaryButton='Cancel'
                type='danger'
                onClick={handleDelete}
            />
            <div className="content-header">
                <div className="container-fluid">
                    <div className='d-flex mb-2 mx-2'>
                        <h3 className="card-title font-weight-bolder h5">Media List</h3>
                        <div className='flex-grow-1' />
                        <button className='btn btn-primary float-right font-weight-bolder' onClick={() => navigate('/media/add')}><IoAdd /> Add</button>
                    </div>
                    <div className="card">

                        {/* <div className="card-header">
                            <div className='row justify-content-end my-1'>
                                <div className='col-lg-4 col-md-6 col-12'>
                                    <input className='form-control' placeholder='Search here...' value={search} onChange={(e) => setSearch(e.target.value)} />
                                </div>
                            </div>
                        </div> */}

                        <div className="card-body">
                            <div className="d-flex flex-wrap">
                                {
                                    media ? media.map((item, i) => (<div key={i} className='m-2'>
                                        <div className='position-relative' style={{ width: 150, height: 150 }}>
                                            <IoMdCloseCircle className='cursor-pointer h4 m-0 position-absolute text-white font-weight-bolder' onClick={() => {
                                                setDeleteId(item?.id);
                                                setShow(true)
                                            }}
                                                style={{ top: '2%', left: '82%' }} />
                                            <img alt={item?.media} className='w-100 h-100 object-fit-cover m-0' src={`${process.env.REACT_APP_IMAGE_URL}${item?.media}`} />
                                        </div>
                                    </div>))
                                        : null
                                }
                            </div>
                        </div>
                        {/* /.card-body */}
                        <div className="card-footer clearfix">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="»"
                                onPageChange={page => setPage(page.selected + 1)}
                                pageRangeDisplayed={2}
                                forcePage={0}
                                className='pagination pagination-md m-0 float-right user-select-none'
                                pageClassName='page-item page-link user-select-none'
                                nextClassName='page-item page-link user-select-none'
                                breakClassName='page-item page-link user-select-none'
                                previousClassName='page-item page-link user-select-none'
                                activeClassName='active'
                                pageCount={totalPage}
                                previousLabel="«"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
