import React, { useEffect, useState } from 'react'
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { PostSchema } from '../../Validation';
import { IoMdCloseCircle } from "react-icons/io";
import HttpService from '../../Api';
import { toast } from 'sonner';
import { useLoader } from '../../Store';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build';
import MediaModal from '../../Components/MediaModal';
import { MdPermMedia } from 'react-icons/md';

export default function Add() {

  const setIsLoading = useLoader(state => state.setIsLoading);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [option, setOption] = useState<{ value: number, label: string }[]>([]);
  const [subCategoryoption, setSubCategoryOption] = useState<{ value: number, label: string }[]>([]);

  const { values, errors, setFieldValue, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      title: "",
      categoryId: null,
      subCategoryId: null,
      isImage: true,
      image: null,
      description: "",
      post: "<p>Write your blog here...</p>",
      metaTitle: "",
      metaDescription: "",
      metaKeyword: "",
      status: 1,
      slug:""
    },
    enableReinitialize: true,
    validationSchema: PostSchema,
    onSubmit: (data) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("slug", data.slug);
      formData.append("categoryId", data.categoryId!);
      if (data.subCategoryId) formData.append("subCategoryId", data.subCategoryId!);
      formData.append("description", data.description);
      formData.append("post", data.post);
      formData.append("metaTitle", data.metaTitle);
      formData.append("metaDescription", data.metaDescription);
      formData.append("metaKeyword", data.metaKeyword);
      formData.append("status", data.status.toString());
      formData.append("image", data.image!);
      HttpService({
        method: 'post',
        url: '/admin/post/add',
        body: formData
      }).then((resp) => {
        toast.success(resp?.data?.message);
        setIsLoading(false);
        navigate('/post');
      }).catch((err: any) => {
        toast.error(err?.response?.data?.message)
        setIsLoading(false);
      });
    }
  })

  useEffect(() => {
    HttpService({
      url: `/admin/category/list?search=&limit=50&page=${1}`,
      method: 'get'
    }).then(result => setOption(result.data.category.map((item: any) => ({ value: item?.id, label: item?.name })))).catch((err) => toast.error(err?.response?.data?.message));

    HttpService({
      url: `/admin/sub-category/list?search=&limit=50&page=${1}`,
      method: 'get'
    }).then(result => setSubCategoryOption(result.data.subCategory.map((item: any) => ({ value: item?.id, label: item?.name })))).catch((err) => toast.error(err?.response?.data?.message));
  }, [])

  return (
    <div className="content-wrapper">
      <MediaModal
        show={show}
        setShow={setShow}
        onClick={(url, alt) => {
          setFieldValue('post', `${values.post} <figure class="image">
          <img alt="${alt}" style="aspect-ratio:3840/2160;" src="${url}" width="3840" height="2160">
        </figure>`)
          setShow(false)
        }}
      />
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">

            <div className="card-header">
              <h3 className="card-title font-weight-bolder h5">Add Post</h3>
              <button className='btn btn-info float-right font-weight-bolder' onClick={() => navigate(-1)}><IoArrowBack /> Back</button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className='row'>
                  {/* Post  Name*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="title">Post Title</label>
                      <input type="text" className="form-control" id="title" value={values.title} onChange={handleChange} placeholder="Please Enter Post Name." />
                      {errors.title && touched.title ? <div className='text-danger h6'>{errors.title}</div> : null}
                    </div>
                  </div>

                  {/* Post  Slug*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="slug">Post Slug</label>
                      <input type="text" className="form-control" id="slug" value={values.slug} onChange={handleChange} placeholder="Please Enter Post Slug." />
                      {errors.slug && touched.slug ? <div className='text-danger h6'>{errors.slug}</div> : null}
                    </div>
                  </div>

                  {/* Post  Description*/}
                  <div className='col-12'>
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <textarea rows={3} className="form-control textarea" id="description" value={values.description} onChange={handleChange} placeholder="Please Enter Description." />
                      {errors.description && touched.description ? <div className='text-danger h6'>{errors.description}</div> : null}
                    </div>
                  </div>

                  {/* Post  category*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="categoryId">Select Category</label>
                      <Select value={option.filter(item => item.value === values?.categoryId)} onChange={(e) => setFieldValue('categoryId', e?.value)} options={option} />
                      {errors.categoryId && touched.categoryId ? <div className='text-danger h6'>{errors.categoryId}</div> : null}
                    </div>
                  </div>

                  {/* Post  category*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="subCategoryId">Select Sub Category</label>
                      <Select value={subCategoryoption.filter(item => item.value === values?.subCategoryId)} onChange={(e) => setFieldValue('subCategoryId', e?.value)} options={subCategoryoption} />
                      {errors.subCategoryId && touched.subCategoryId ? <div className='text-danger h6'>{errors.subCategoryId}</div> : null}
                    </div>
                  </div>

                  {/* Select  Media*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="subCategoryId">Select Media</label>
                      <button type='button' onClick={() => setShow(true)} className='btn btn-info d-block w-100 fw-bolder'> <MdPermMedia className='mx-2' size={24} />Select Media</button>
                    </div>
                  </div>

                  {/* Post  Post*/}
                  <div className='col-12'>
                    <div className="form-group">
                      <label htmlFor="post">Post</label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={{removePlugins:['Title']}}
                        data={values.post}
                        onChange={(_, editor) => setFieldValue('post', editor.getData())}
                      />
                      {errors.post && touched.post ? <div className='text-danger h6'>{errors.post}</div> : null}
                    </div>
                  </div>

                  {/* Post  Image*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="image">Image</label>
                      {
                        values?.image ?
                          <div className='position-relative' style={{ width: 150, height: 150 }}>
                            <IoMdCloseCircle className='cursor-pointer h4 m-0 position-absolute text-white font-weight-bolder' onClick={() => setFieldValue('image', null)} style={{ top: '2%', left: '82%' }} />
                            <img alt='media' className='w-100 h-100 object-fit-cover m-0' src={URL.createObjectURL(values?.image)} />
                          </div> :
                          <input accept="image/*" onChange={(e: any) => setFieldValue('image', e.target.files[0])} type="file" readOnly={true} className="form-control" id="image" placeholder="Please Enter Post Name." />
                      }
                      {errors.image && touched.image ? <div className='text-danger h6'>{errors.image}</div> : null}
                    </div>
                  </div>

                  {/* Post  Meta Title*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="metaTitle">Meta Title</label>
                      <input type="text" className="form-control" id="metaTitle" value={values.metaTitle} onChange={handleChange} placeholder="Please Enter Meta Title." />
                      {errors.metaTitle && touched.metaTitle ? <div className='text-danger h6'>{errors.metaTitle}</div> : null}
                    </div>
                  </div>

                  {/* Post  Meta Description*/}
                  <div className='col-12'>
                    <div className="form-group">
                      <label htmlFor="metaDescription">Meta Description</label>
                      <textarea rows={3} className="form-control textarea" id="metaDescription" value={values.metaDescription} onChange={handleChange} placeholder="Please Enter Meta Description." />
                      {errors.metaDescription && touched.metaDescription ? <div className='text-danger h6'>{errors.metaDescription}</div> : null}
                    </div>
                  </div>

                  {/* Post  Meta KeyWord*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="metaKeyword">Meta Keyword</label>
                      <textarea rows={3} className="form-control" id="metaKeyword" value={values.metaKeyword} onChange={handleChange} placeholder="Please Enter Meta Keyword." />
                      {errors.metaKeyword && touched.metaKeyword ? <div className='text-danger h6'>{errors.metaKeyword}</div> : null}
                    </div>
                  </div>

                  {/* Post  Status*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select className='form-control' value={values.status} onChange={handleChange} id='status'>
                        <option unselectable="on" hidden>Please select status</option>
                        <option value={1}>Active</option>
                        <option value={0}>Deactive</option>
                      </select>
                      {errors.status && touched.status ? <div className='text-danger h6'>{errors.status}</div> : null}
                    </div>
                  </div>



                </div>

              </div>
              <div className="card-footer clearfix">
                <button className='btn btn-primary mr-2' style={{ width: 100 }} type='submit'>Submit</button>
                <button className='btn btn-secondary ml-2' style={{ width: 100 }} type='button' onClick={() => navigate('/post')}>Cancel</button>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div >
  )
}
