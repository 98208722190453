import React, { useEffect, useState } from 'react'
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { SubCategorySchema } from '../../Validation';
import { IoMdCloseCircle } from "react-icons/io";
import HttpService from '../../Api';
import { toast } from 'sonner';
import { useLoader } from '../../Store';
import Select from 'react-select';

export default function Add() {

  const setIsLoading = useLoader(state => state.setIsLoading);
  const navigate = useNavigate();
  const [option, setOption] = useState<{ value: number, label: string }[]>([]);
  const { values, errors, setFieldValue, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      name: "",
      description: "",
      isImage: true,
      status: 1,
      image: null,
      categoryId: 0
    },
    enableReinitialize: true,
    validationSchema: SubCategorySchema,
    onSubmit: (data) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("status", data.status.toString());
      formData.append("categoryId", data.categoryId.toString());
      formData.append("image", data.image!);
      HttpService({
        method: 'post',
        url: '/admin/sub-category/add',
        body: formData
      }).then((resp) => {
        toast.success(resp?.data?.message);
        setIsLoading(false);
        navigate('/sub-category');
      }).catch((err: any) => {
        toast.error(err?.response?.data?.message)
        setIsLoading(false);
      });
    }
  });

  useEffect(() => {
    HttpService({
      url: `/admin/category/list?search=&limit=50&page=${1}`,
      method: 'get'
    }).then(result => setOption(result.data.category.map((item: any) => ({ value: item?.id, label: item?.name })))).catch((err) => toast.error(err?.response?.data?.message));
  }, [])

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="card">

            <div className="card-header">
              <h3 className="card-title font-weight-bolder h5">Add Sub Category</h3>
              <button className='btn btn-info float-right font-weight-bolder' onClick={() => navigate(-1)}><IoArrowBack /> Back</button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <div className='row'>
                  {/* SubCategory  Name*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="name">Sub Category Name</label>
                      <input type="text" className="form-control" id="name" value={values.name} onChange={handleChange} placeholder="Please Enter SubCategory Name." />
                      {errors.name && touched.name ? <div className='text-danger h6'>{errors.name}</div> : null}
                    </div>
                  </div>

                  {/* SubCategory  category*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="categoryId">Select Category</label>
                      <Select value={option.filter(item => item.value === values?.categoryId)} onChange={(e) => setFieldValue('categoryId', e?.value)} options={option} />
                      {errors.categoryId && touched.categoryId ? <div className='text-danger h6'>{errors.categoryId}</div> : null}
                    </div>
                  </div>


                  {/* SubCategory  Description*/}
                  <div className='col-12'>
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <textarea rows={3} className="form-control textarea" id="description" value={values.description} onChange={handleChange} placeholder="Please Enter Description." />
                      {errors.description && touched.description ? <div className='text-danger h6'>{errors.description}</div> : null}
                    </div>
                  </div>

                  {/* SubCategory  Status*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select className='form-control' value={values.status} onChange={handleChange} id='status'>
                        <option unselectable="on" hidden>Please select status</option>
                        <option value={1}>Active</option>
                        <option value={0}>Deactive</option>
                      </select>
                      {errors.status && touched.status ? <div className='text-danger h6'>{errors.status}</div> : null}
                    </div>
                  </div>

                  {/* SubCategory  Image*/}
                  <div className='col-lg-6 col-12'>
                    <div className="form-group">
                      <label htmlFor="image">Image</label>
                      {
                        values?.image ?
                          <div className='position-relative' style={{ width: 150, height: 150 }}>
                            <IoMdCloseCircle className='cursor-pointer h4 m-0 position-absolute text-white font-weight-bolder' onClick={() => setFieldValue('image', null)} style={{ top: '2%', left: '82%' }} />
                            <img alt='media' className='w-100 h-100 object-fit-cover m-0' src={URL.createObjectURL(values?.image)} />
                          </div> :
                          <input accept="image/*" onChange={(e: any) => setFieldValue('image', e.target.files[0])} type="file" readOnly={true} className="form-control" id="image" placeholder="Please Enter SubCategory Name." />
                      }
                      {errors.image && touched.image ? <div className='text-danger h6'>{errors.image}</div> : null}
                    </div>
                  </div>

                </div>

              </div>
              <div className="card-footer clearfix">
                <button className='btn btn-primary mr-2' style={{ width: 100 }} type='submit'>Submit</button>
                <button className='btn btn-secondary ml-2' style={{ width: 100 }} type='button' onClick={() => navigate('/subCategory')}>Cancel</button>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div >
  )
}
