import React from 'react'
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { MediaSchema } from '../../Validation';
import { IoMdCloseCircle } from "react-icons/io";
import HttpService from '../../Api';
import { toast } from 'sonner';
import { useLoader } from '../../Store';

export default function MediaAdd() {

    const setIsLoading = useLoader(state => state.setIsLoading);
    const navigate = useNavigate();
    const { values, errors, setFieldValue, touched,handleSubmit } = useFormik({
        initialValues: {
            image: [],
        },
        enableReinitialize: true,
        validationSchema: MediaSchema,
        onSubmit: (data) => {
            setIsLoading(true);
            const formData = new FormData();
            data?.image.map(img => formData.append("images", img));
            HttpService({
                method: 'post',
                url: '/admin/media/add',
                body: formData
            }).then((resp) => {
                toast.success(resp?.data?.message);
                setIsLoading(false);
                navigate('/media');
            }).catch((err: any) => {
                toast.error(err?.response?.data?.message)
                setIsLoading(false);
            });
        }
    })

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="card">

                        <div className="card-header">
                            <h3 className="card-title font-weight-bolder h5">Add Media</h3>
                            <button className='btn btn-info float-right font-weight-bolder' onClick={() => navigate(-1)}><IoArrowBack /> Back</button>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className='row'>

                                    {/* Media  Image*/}
                                    <div className='col-lg-6 col-12'>
                                        <div className="form-group">
                                            <label htmlFor="image">Image</label>
                                            {
                                                !values?.image[0] &&
                                                <input multiple accept="image/*" onChange={(e: any) => setFieldValue('image', Array.from(e.target.files))} type="file" readOnly={true} className="form-control" id="image" placeholder="Please Enter Media Name." />
                                            }
                                            {errors.image && touched.image ? <div className='text-danger h6'>{errors.image}</div> : null}
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className='d-flex flex-wrap'>
                                            {
                                                values?.image?.map((item: any, i: number) => (
                                                    <div key={i} className='position-relative m-2' style={{ width: 150, height: 150 }}>
                                                        <IoMdCloseCircle className='cursor-pointer h4 m-0 position-absolute text-white font-weight-bolder' onClick={() => setFieldValue('image', values.image.filter(img => img !== item))} style={{ top: '2%', left: '82%' }} />
                                                        <img alt='media' className='w-100 h-100 object-fit-cover m-0' src={URL.createObjectURL(item)} />
                                                    </div>))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer clearfix">
                                <button className='btn btn-primary mr-2' style={{ width: 100 }} type='submit'>Submit</button>
                                <button className='btn btn-secondary ml-2' style={{ width: 100 }} type='button' onClick={() => navigate('/media')}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div >
    )
}
