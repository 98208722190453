import React from 'react';
import Layout from './Layout';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdminRoutes from './Routes';
import Login from './Pages/Login';
import PageNotFound from './Pages/PageNotFound';
import AdminLogged from './Middleware/AdminLogged';
import ProtectedRoute from './Middleware/AdminProtectedRoute';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<AdminLogged><Login /></AdminLogged>} />
          <Route path='' element={<ProtectedRoute ><Layout /></ProtectedRoute>}>
            {AdminRoutes.map((item, i) => (<Route path={item.path} element={item.element} key={i} />))}
          </Route>
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
