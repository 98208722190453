import React from 'react'
import { FaUserCircle } from "react-icons/fa";
import { IoExit } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

export default function Navbar() {
    const navigate = useNavigate()
    return (
        <nav className="main-header navbar navbar-expand navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <div className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars" /></div>
                </li>
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                {/* Notifications Dropdown Menu */}
                <li className="nav-item dropdown">
                    <div className="nav-link" data-toggle="dropdown">
                        <FaUserCircle className='h2' />
                    </div>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <div onClick={() => {
                            localStorage.removeItem('admin_token');
                            navigate('/login');
                        }} className="cursor-pointer h5 text-danger d-flex justify-content-center align-items-center mb-0">
                            <IoExit className="mr-2" /> Logout
                        </div>
                    </div>
                </li>
            </ul>
        </nav>

    )
}
