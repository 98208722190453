import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const getToken = () => {
  const token = localStorage.getItem('admin_token');
  return token;
};

instance.interceptors.request.use(
  async (config) => {
    const token = getToken();
    if (token) {
      config.headers.token = `${token}`;
    }
    return config;
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('admin_token');
      window.location.replace('/');
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
