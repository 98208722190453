import React from 'react'
import { useLoader } from '../Store'

export default function Loader() {
    const isLoading = useLoader(state => state.isLoading);
    return (
        <>
            {isLoading &&
                < div className='full-loader' >
                    <div className='loader'>
                        <div className='circle circle-1' />
                        <div className='circle circle-2' />
                        <div className='circle circle-3' />
                    </div>
                </div >
            }
        </>
    )
}
